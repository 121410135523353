<template>
  <div class="py-4 px-2 p-md-4">
    <a-button
      class="float-right mb-3 d-flex align-items-center"
      type="primary"
      @click="handleShowForm"
      id="add-user-btn"
    >
      <template #icon><i class="bi bi-person-plus mr-2 mb-1"></i></template>
      Add User
    </a-button>

    <users-list
      :list="list"
      @deleteUser="handleDeleteUser"
      :isFetchingUsers="isFetchingUsers"
    ></users-list>

    <a-modal
      v-model:visible="showForm"
      centered
      :closable="false"
      title="Add user"
      :footer="null"
      id="add-user-modal"
      destroyOnClose
    >
      <add-user-new
        :roles="roleList"
        @addUser="addUser"
        @cancel="handleShowForm"
        :isLoading="isLoading"
      ></add-user-new>
    </a-modal>
  </div>
</template>
<script>
import UsersList from './UsersList.vue';
import AddUserNew from './AddUserNew.vue';
import { useToast } from 'vue-toastification';
import Auth from 'src/services/auth.js';
import httpClient from 'src/service/httpClient';
import { roles, roleLabels } from 'src/config/roles-config';
import { mapGetters } from 'vuex';

export default {
  name: 'OrgUsers',
  components: {
    UsersList,
    AddUserNew
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      list: [],
      roleList: [],
      showForm: false,
      isLoading: false,
      isFetchingUsers: false
    };
  },

  created() {
    this.fetchUsersAndRoles();
  },
  computed: {
    ...mapGetters(['organization'])
  },

  methods: {
    doSomething() {
      let i = 0;
      while (i <= 1000000) {
        i++;
        console.log(i);
      }
    },

    async fetchUsersAndRoles() {
      try {
        this.isFetchingUsers = true;
        const [roles, users] = await Promise.all([
          this.fetchRoles(),
          this.fetchUsers()
        ]);
        this.roleList = roles;

        const tempRoles = roles.reduce((res, el) => {
          res[el.id] = roleLabels[el.name];
          return res;
        }, {});

        this.list = users.map(u => ({ ...u, role: tempRoles[u.role] }));
      } catch (error) {
        console.log('error in users list:', error);
      } finally {
        this.isFetchingUsers = false;
      }
    },

    fetchUsers() {
      return new Promise(async (resolve, reject) => {
        try {
          const reqRoles = [
            roles.org_user,
            roles.analytics_user,
            roles.workstation_user,
            roles.user
          ].join(',');
          let users = await this.getUsers(reqRoles);
          users = users.sort((a, b) => a.id - b.id);
          return resolve(users);
        } catch (error) {
          reject();
        }
      });
    },

    async getUsers(role) {
      return await httpClient.get(
        `account/users_list?role__name=${role}&Organization=${this.organization}`,
        false
      );
    },

    async fetchRoles() {
      return await httpClient.get('account/roles/', false);
    },

    async addUser(payload) {
      this.isLoading = true;
      const [error, data] = await Auth.signUp(payload);
      if (error) {
        error.response.status == 400
          ? this.toast.error('Username already exists')
          : this.toast.error(error);
        return;
      }
      this.toast.success(data.response);
      this.isLoading = false;
      if (!error) this.handleShowForm();
      if (payload.role != 6) this.assignUserToOrg(payload.email);
    },

    async assignUserToOrg(user_email) {
      const data = {
        email: user_email,
        Organization_name: this.organization
      };
      const res = await httpClient.put(
        'account/',
        'assign_user_org',
        data,
        true,
        true,
        false
      );
      if (res === 'error') {
        this.toast.error('Failed to assign organization!');
        return;
      }

      this.isLoading = false;
      this.showForm = false;

      this.fetchUsersAndRoles();
    },

    async handleDeleteUser(email) {
      const res = await httpClient.delete(
        'account/update-delete/',
        `${email}/`,
        true,
        true,
        true,
        'User Deleted Successfully!'
      );
      this.list = this.list.filter(u => u.email !== email);
    },
    handleShowForm() {
      this.showForm = !this.showForm;
    }
  }
};
</script>
<style></style>
