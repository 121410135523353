<template>
  <a-form
    layout="vertical"
    :model="form"
    name="update-work-station-user"
    :rules="rules"
    class="edit-user-form"
    @finish="onSubmit"
  >
    <div class="row">
      <div class="d-none d-sm-flex col-sm-1 center">
        <div class="pre-icon os-icon os-icon-user"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Username" name="username">
          <a-input
            v-model:value="form.username"
            placeholder="Enter username"
            id="add-workstation-username"
            disabled
            class="text-dark"
          >
          </a-input>
        </a-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-fingerprint"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item label="Password" name="password" autocomplete="off">
          <a-input-password
            v-model:value="form.password"
            placeholder="Password"
            @change="validPasswordPattern(form.password)"
          />
          <div v-if="this.errors.length">
            <span style="color:red" v-for="error in errors" :key="error">
              {{ error }}
            </span>
          </div>
        </a-form-item>
      </div>

      <div class="col-sm-1 center">
        <div class="pre-icon os-icon os-icon-fingerprint"></div>
      </div>
      <div class="col-sm-11">
        <a-form-item
          label="Confirm Password"
          name="confirmPassword"
          autocomplete="off"
        >
          <a-input-password
            v-model:value="form.confirmPassword"
            placeholder="Confirm Password"
            type="password"
            @change="checkPassword"
          />
          <p v-if="!isPasswordSame && form.confirmPassword" style="color:red">
            The value must be equal to the other value
          </p>
        </a-form-item>
      </div>
    </div>

    <a-space class="d-flex justify-content-center">
      <a-button
        type="primary"
        size="large"
        html-type="submit"
        :disabled="isLoading"
        :loading="isLoading"
      >  
        Update
      </a-button>
      <a-button @click="onCancel" size="large">
        Cancel
      </a-button>
    </a-space>
  </a-form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
  props: ['username','isLoading'],
  mounted() {
    this.form.username = this.username;
  },
  setup() {
    const v$ = useVuelidate();
    const rules = {
      username: [{ required: true, trigger: 'submit' }],
      password: [{ required: true, min: 6, trigger: 'submit' }],
      confirmPassword: [{ required: true, trigger: 'submit' }]
    };
    return { rules, v$ };
  },
  data() {
    return {
      isPasswordSame: true,
      options: [],
      errors: [],
      form: {
        username: '',
        password: '',
        confirmPassword: ''
      }
    };
  },

  validations() {
    return {
      form: {
        username: { required },
        password: { required, min: minLength(6) },
        confirmPassword: { required }
      }
    };
  },
  methods: {
    checkPassword() {
      this.form.password != this.form.confirmPassword
        ? (this.isPasswordSame = false)
        : (this.isPasswordSame = true);
    },

    onSubmit() {
      const data = {
        username: this.form.username,
        password: this.form.password,
        confirm_password: this.form.confirmPassword
      };
      this.$emit('updateWorkStationUser', data);
    },
    onCancel() {
      this.$emit('cancel');
    },
    async validPasswordPattern(password) {
      this.errors = [];
      if (password.length < 8) {
        this.errors.push('Your password must be at least 8 characters. ');
      }
      if (password.search(/[a-z]/i) < 0) {
        this.errors.push('Your password must contain at least one letter. ');
      }
      if (password.search(/[0-9]/) < 0) {
        this.errors.push('Your password must contain at least one digit. ');
      }
      if (this.errors.length > 0) {
        return false;
      }
      return Promise.resolve();
    }
  }
};
</script>

<style scoped>
.center {
  display: flex;
  align-items: center;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
}
</style>
